<template>
  <b-row>
    <div class="form-wrapper">
      <template v-if="isLoading">
        <div class="spinner-container-main">
          <b-spinner></b-spinner>
        </div>
      </template>
      <template v-else>
        <template v-if="!isAppNotExist">
          <template v-if="!isAppCompleted">
            <div class="form-wrapper-left">
              <div class="logo-container">
                <img alt="Logo" class="logo" src="@/assets/logo.png" />
              </div>
              <div class="header-text-container">
                <p>All Questions MUST be answered.</p>
                <p>If you do NOT answer all Questions, we will NOT be able to proceed with your application.</p>
              </div>
            </div>
            <div class="form-wrapper-right">
              <div class="header-container">
                <div class="header-title">
                  <span class="header-title-step"> </span>
                </div>
              </div>

              <div class="form-container">
                <div class="form-container-title px-5 pt-5">
                  <template v-if="steps.length">
                    <b>{{ steps[0].stepNameFull }}</b>
                  </template>
                </div>
                <PersonalStep
                  :personal="formData.personal"
                  :is-loading="isLoadingStep"
                  :on-submit="sendForm"
                  ref="PersonalStepComponent"
                />
                <div class="form-container-title px-5 pt-5">
                  <template v-if="steps.length">
                    <b>{{ steps[1].stepNameFull }}</b>
                  </template>
                </div>
                <RelationshipStep
                  :relationship="formData.relationship"
                  :is-loading="isLoadingStep"
                  :on-submit="sendForm"
                  ref="RelationshipStepComponent"
                />
                <div class="form-container-title px-5 pt-5">
                  <template v-if="steps.length">
                    <b>{{ steps[2].stepNameFull }}</b>
                  </template>
                </div>
                <UkWorkStep
                  :ukwork="formData.ukwork"
                  :is-loading="isLoadingStep"
                  :on-submit="sendForm"
                  ref="EmploymentStepComponent"
                />
                <div class="form-container-title px-5 pt-5">
                  <template v-if="steps.length">
                    <b>{{ steps[3].stepNameFull }}</b>
                    <div v-if="steps[3].stepNameFull == 'Work After UK'" class="subtext">
                      <b class="text-red">
                        Please list ALL your activity - with addresses and dates - since you FIRST left the UK up to
                        NOW!
                      </b>
                    </div>
                  </template>
                </div>
                <WorkAfterUkStep
                  :workAfterUK="formData.workAfterUK"
                  :is-loading="isLoadingStep"
                  :on-submit="sendForm"
                  ref="DatesStepComponent"
                />
                <div class="form-container-title px-5 pt-5 text-center">
                  <b class="text-red">Agent</b>
                  <div class="subtext">
                    <b>What Info Is Missing? Email Client Now as Reminder:</b>
                  </div>
                </div>
                <DocumentsStep
                  :personal="formData.personal"
                  :documents="formData.documents"
                  :agent-authorisation="agentAuthorisation"
                  :missing-info="formData.missingInfo"
                  :is-loading="isLoadingStep"
                  :is-full-form="true"
                  :on-submit="sendForm"
                  ref="DocumentsStepComponent"
                />
                <!-- <hr />
              <div class="text-center">
                <div class="error">LASTLY…</div>
                <p>
                  <a
                    href="https://calendly.com/d/ckhd-36c-tyt/xtrapension-ltd-uk-state-pension-application-review?month=2024-05"
                    target="_blank"
                    ><b>Schedule NIAMH call in 2-3 days</b></a
                  >
                  <br />
                  to get their missing info
                </p>
              </div> -->
                <div v-if="isLoadingStep" class="spinner-container mt-3 mb-3 p-2">
                  <b-spinner></b-spinner>
                </div>
                <div class="form-btns-container">
                  <div class="m-3">
                    <div>
                      <button class="btn-main" @click="submitForm($event)" :disabled="isLoadingStep">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="invalid-container">
              <div class="flex-center">
                <img alt="Logo" class="p-3" src="@/assets/logo.png" />
                <h3 class="text-center">Your Application has already been Submitted.</h3>
                <h3 class="text-center">Thank You!</h3>
                <p class="p-4 text-center">
                  Our team is now reviewing it and should be back to you in a few days for your approval and to proceed.
                </p>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="invalid-container">
            <div class="flex-center">
              <img alt="Logo" class="p-3" src="@/assets/logo.png" />
              <h3 class="text-center">This link is not valid.</h3>
            </div>
          </div>
        </template>
      </template>
    </div>
  </b-row>
</template>
<script>
// API
import FormService from '@/services/form-service/form.service';
import { toast } from 'vue3-toastify';
// Components
import ProgressBar from '@/components/form-layouts/ProgressBar/ProgressBar.vue';
import PersonalStep from '@/components/form-layouts/PersonalStep/PersonalStep.vue';
import RelationshipStep from '@/components/form-layouts/RelationshipStep/RelationshipStep.vue';
import UkWorkStep from '@/components/form-layouts/UkWorkStep/UkWorkStep.vue';
import WorkAfterUkStep from '@/components/form-layouts/WorkAfterUkStep/WorkAfterUkStep.vue';
import DocumentsStep from '@/components/form-layouts/DocumentsStep/DocumentsStep.vue';
import '../FormView/FormView.scss';
/* eslint-disable */
export default {
  name: 'FullFormView',
  components: {
    ProgressBar,
    PersonalStep,
    RelationshipStep,
    UkWorkStep,
    WorkAfterUkStep,
    DocumentsStep
  },
  data() {
    return {
      isLoading: true,
      isLoadingStep: false,
      isAppNotExist: true,
      isAppCompleted: false,
      formId: null,
      steps: [
        {
          id: 1,
          active: false,
          stepName: 'Personal',
          stepNameFull: 'Personal Details',
          stepNameBreadcrumbs: 'Personal - Details & Addresses',
          stepQuestionText: '(1 - 12)'
        },
        {
          id: 2,
          active: false,
          stepName: 'Relationship',
          stepNameFull: 'Relationship Details',
          stepNameBreadcrumbs: 'Relationship - Details & Dates',
          stepQuestionText: '(13 - 18)'
        },
        {
          id: 3,
          active: false,
          stepName: 'UK Work',
          stepNameFull: 'UK Work',
          stepNameBreadcrumbs: 'Your Last UK Work - Details & Dates',
          stepQuestionText: '(19 - 25)'
        },
        {
          id: 4,
          active: false,
          stepName: 'Work After UK',
          stepNameFull: 'Work After UK',
          stepNameBreadcrumbs: 'Work After You Left The UK - Details & Dates',
          stepQuestionText: '(26 - 28)'
        },
        {
          id: 5,
          active: false,
          stepName: 'Document(s)',
          stepNameFull: 'Important Document Needed',
          stepNameBreadcrumbs: 'Supporting Document(s) & Finish!',
          stepQuestionText: ''
        }
      ],
      formData: {
        personal: {
          salutation: null,
          firstName: null,
          secondName: null,
          dateOfBirth: null,
          isKnowNationalInsuranceNumber: false,
          insuranceNumber: null,
          workOrPayUKNationalInsurance: false,
          privateUKPension: false,
          approxValue: 0,
          currentOccupation: null,
          currentAddressAbroad: {
            country: 'IE',
            street: null,
            city: null,
            state: null,
            postcode: null
          },
          lastUKHomeAddress: {
            country: 'GB',
            street: null,
            city: null,
            state: null,
            postcode: null,
            fromDate: null,
            toDate: null
          },
          lastUKHomeAddress2: {
            country: 'GB',
            street: null,
            city: null,
            state: null,
            postcode: null,
            fromDate: null,
            toDate: null
          },
          lastUKHomeAddress3: {
            country: 'GB',
            street: null,
            city: null,
            state: null,
            postcode: null,
            fromDate: null,
            toDate: null
          },
          previousUKAddress: []
        },
        relationship: {
          single: false,
          dateOfMarriedOrPartnership: null,
          changeYouName: false,
          fullMaindenOrPreviousName: null,
          divorcedOrwidowed: false,
          exactDate: null
        },
        ukwork: {
          dateOfLeavingLastUKEmployment: null,
          yearsWorkInUK: 0,
          workInUKBeforeLeaving: null,
          nameOfLastUKEmployer: null,
          lastUKEmployerAddress: {
            country: 'GB',
            street: null,
            city: null,
            state: null,
            postcode: null
          },
          startDateOfLastUKEmployer: null,
          endDateOfLastUKEmployer: null
        },
        workAfterUK: [
          {
            afterLeftTheUK: null,
            nameOfYourEmployerAbroad: null,
            country: null,
            street: null,
            city: null,
            state: null,
            postcode: null,
            startDate: null,
            endDate: null,
            isCurrently: false
          }
        ],
        documents: {
          applicationId: null,
          applicationName: null,
          statements: null
        }
      },
      agentAuthorisation: {
        termsAndConditions: false,
        basic: false
      },
      missingInfo: {
        nino: false,
        prsi: false,
        lastUKEmpl: false,
        workAfterUK: false,
        relationship: false
      }
    };
  },
  mounted() {
    console.log('Transaction ID', this.$route.params.id);
    if (this.$route?.params?.id) {
      this.formId = this.$route?.params?.id;
      this.initForm();
      setInterval(async () => {
        if (
          !this.isLoading &&
          !this.isLoadingStep &&
          !this.isAppNotExist &&
          !this.formData.documents.statements?.base64?.length
        ) {
          this.submitAutoSaveForm();
        }
      }, 120 * 1000);
    }
  },
  methods: {
    async initForm() {
      this.showSpinner();
      try {
        const { status, formData, currentStep } = await FormService.getFormData(this.formId, true);
        console.log('Init Form', status, formData, currentStep);
        if (status === 'Success') {
          this.isAppNotExist = false;
          this.formData = { ...this.formData, ...formData };
        } else {
          toast.warning(status ?? 'Oops, Something Went Wrong');
        }
      } catch (error) {
        console.log(error);
        const { message } = error;
        if (message === 'Application completed') {
          this.isAppCompleted = true;
          this.isAppNotExist = false;
        } else {
          toast.error(error.message ?? 'Oops, Something Went Wrong');
        }
      } finally {
        this.hideSpinner();
      }
    },
    async submitAutoSaveForm() {
      try {
        // await this.$refs.PersonalStepComponent.setTouched("all");
        // await this.$refs.RelationshipStepComponent.setTouched("all");
        // await this.$refs.EmploymentStepComponent.setTouched("all");
        // await this.$refs.DocumentsStepComponent.setTouched("all");

        // const components = [
        //   this.$refs.PersonalStepComponent,
        //   this.$refs.RelationshipStepComponent,
        //   this.$refs.EmploymentStepComponent,
        //   this.$refs.DatesStepComponent,
        //   this.$refs.DocumentsStepComponent,
        // ];

        // let isValidAllForm = components.every((item) => !item.v$.$invalid);
        // if (isValidAllForm) {
        this.sendForm(true);
        // } else toast.error("Form Validation Error!");
      } catch (error) {
        console.log(error);
      }
    },
    async submitForm(event) {
      event?.preventDefault();
      event?.stopPropagation();
      try {
        // await this.$refs.PersonalStepComponent.setTouched("all");
        // await this.$refs.RelationshipStepComponent.setTouched("all");
        // await this.$refs.EmploymentStepComponent.setTouched("all");
        // await this.$refs.DocumentsStepComponent.setTouched("all");

        // const components = [
        //   this.$refs.PersonalStepComponent,
        //   this.$refs.RelationshipStepComponent,
        //   this.$refs.EmploymentStepComponent,
        //   this.$refs.DatesStepComponent,
        //   this.$refs.DocumentsStepComponent,
        // ];

        // let isValidAllForm = components.every((item) => !item.v$.$invalid);
        // if (isValidAllForm) {
        //   this.sendForm();
        // } else toast.error("Form Validation Error!");
        this.sendForm();
      } catch (error) {
        console.log(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      }
    },
    async sendForm(isAutoUpdate = false) {
      this.showStepSpinner();
      try {
        const { status, isComplete } = await FormService.sendFullFormData(
          {
            ...this.formData,
            isAutoUpdate: isAutoUpdate
          },
          this.formId
        );
        if (status === 'Success') {
          if (isAutoUpdate) {
            toast.success('Form Auto Saved!');
          } else {
            if (isComplete === true) {
              window.open('https://xtrapension.com/thank-you', '_self');
            } else {
              toast.warning('Oops, Something Went Wrong');
            }
          }
        } else {
          toast.warning(status ?? 'Oops, Something Went Wrong');
        }
      } catch (error) {
        toast.error(error.message ?? 'Oops, Something Went Wrong');
        console.log(error);
      } finally {
        this.hideStepSpinner();
      }
    },
    showSpinner() {
      this.isLoading = true;
    },
    hideSpinner() {
      this.isLoading = false;
    },
    showStepSpinner() {
      this.isLoadingStep = true;
    },
    hideStepSpinner() {
      this.isLoadingStep = false;
    }
  }
};
</script>
