<template>
  <b-container v-if="!isPackageSelected">
    <div class="packages-wrapper">
      <b-col cols="12">
        <div class="flex-center text-center mt-3">
          <h1>
            <b>Get a UK State Pension</b> <br />
            or Your Money Back.
          </h1>
        </div>
      </b-col>
      <b-col cols="12">
        <Packages :on-select="handleSelectPackage" :packages-list="packagesList" />
      </b-col>
    </div>
  </b-container>
  <template v-else>
    <b-container v-if="stripeLoaded">
      <div v-if="!isPayCompleted" class="payment-form">
        <b-row>
          <b-col>
            <div class="flex-center">
              <img alt="Logo" src="@/assets/logo.png" />
            </div>
          </b-col>
          <b-col cols="12" lg="12"><label class="card-label">Email:</label></b-col>
          <b-col cols="12" lg="12">
            <b-form-input
              type="email"
              v-model.trim="email"
              @input="setTouched('email')"
              :class="v$.email.$error ? 'is-invalid' : ''"
              placeholder="Enter Your Email"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="12">
            <label class="card-label">Personal Details</label>
            <div id="address-element"></div>
          </b-col>
          <b-col cols="12" lg="12">
            <label class="card-label">Card Number</label>
            <div id="card-element"></div>
          </b-col>
          <b-col cols="12" lg="12" class="mt-3">
            <b-row>
              <b-col cols="12"><label>How did you hear about us?</label></b-col>
              <b-col cols="12">
                <div class="input-container">
                  <b-form-textarea
                    v-model.trim="howDidYouHearAboutUs"
                    :disabled="isPayLoading"
                    placeholder="Enter"
                    maxlength="255"
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <label>Which Agent Did You Speak to?</label>
              </b-col>
              <b-col cols="12">
                <div class="input-container">
                  <b-form-select
                    v-model="agent"
                    :options="$route.name === 'payWithMoto' ? agentsOptionsForMoto : agentsOptions"
                    placeholder="Choose"
                    :disabled="isPayLoading"
                  >
                  </b-form-select>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="isPayWithMoto" cols="12">
            <div class="flex-center mt-3">
              <b-form-checkbox v-model="class3Appeal" name="c3Appeal" :disabled="isPayLoading">
                Class 3 Appeal
              </b-form-checkbox>
            </div>
            <!-- <div class="flex-center mt-3">
              <b-form-checkbox v-model="paymentAfterJan" name="c3Appeal" :disabled="isPayLoading">
                Payment After Jan
              </b-form-checkbox>
            </div> -->
            <div class="mt-3">
              <b-row>
                <b-col cols="12">
                  <label>Has NINO Now?</label>
                </b-col>
                <b-col cols="12">
                  <div class="input-container">
                    <b-form-select
                      v-model="hasNINONow"
                      :options="yesNoOptions"
                      placeholder="Choose"
                      :disabled="isPayLoading"
                    >
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="flex-center mt-3">
              <b-form-checkbox v-model="isAgreeTermsConditions" name="isAgreeTermsConditions" :disabled="isPayLoading">
                I agree to XtraPension's
                <a href="https://www.xtrapension.com/terms" target="_blank">Terms & Conditions</a>
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="12" lg="12">
            <template v-if="isPayLoading">
              <div class="spinner-container p-3">
                <b-spinner></b-spinner>
              </div>
            </template>
            <div class="flex-center">
              <button class="btn-main" :disabled="isPayLoading || !isAgreeTermsConditions" @click="pay">
                Pay € {{ formatAmount(amount) }}
              </button>
            </div>
            <div class="flex-center invalid-feedback" v-for="error of v$.email.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
            <div class="flex-center invalid-feedback">
              {{ stripeValidationError }}
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else class="pay-completed-form">
        <b-row>
          <b-col>
            <div class="flex-center">
              <img alt="Logo" src="@/assets/logo.png" />
            </div>
          </b-col>
          <b-col cols="12" lg="12">
            <div class="flex-center">
              <template v-if="$route?.name === 'payWithMoto'">
                <h1>Payment Received.</h1>
                <h1>Thanks!</h1>
                <p>---------------------------------</p>
                <p class="mt-3">Agent! Now Get Their Info While You Can.</p>
                <p>
                  (They can reply to our ‘Welcome’ email that they just got with National Insurance No + PRSI statement
                  <b>LATER</b> if they want)
                </p>
                <p class="mt-3 mb-3">
                  <b><a href="https://xpen.uk/PostSaleQs">Get Their Info</a></b>
                </p>
                <p>*Giving us this info now allows us to do everything for them quickly.</p>
                <p class="mb-3">(But we will STILL need PRSI doc + Nat Ins no if they have it)</p>
                <template v-if="isDisabledFullFormLink">
                  <div class="spinner-container p-3">
                    <b-spinner></b-spinner>
                  </div>
                </template>
                <button class="btn-main" :disabled="isDisabledFullFormLink" @click="navigateToFullForm(payCompletedId)">
                  Agent Full Form
                </button>
              </template>
              <template v-else>
                <h1>Thanks for Payment!</h1>
                <p class="mt-3 mb-3">A receipt has been emailed to you. (Check your spam/junk folder)</p>
                <template v-if="isAppCreateLoading">
                  <div class="spinner-container p-3">
                    <b-spinner></b-spinner>
                  </div>
                </template>
                <p v-if="isAppCreateError" class="invalid-feedback p-3">
                  If you see this message, then something has gone wrong. Please contact us. And tell us your
                  transaction id (<b>{{ payCompletedId }}</b
                  >)
                </p>
                <button class="btn-main" @click="navigateToForm(payCompletedId)" :disabled="isDisabledFormLink">
                  Start
                </button>
              </template>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-container v-else>
      <b-row>
        <template v-if="isAppCreateLoading">
          <div class="flex-center">
            <div class="spinner-container p-3">
              <b-spinner></b-spinner>
            </div>
          </div>
        </template>
      </b-row>
    </b-container>
  </template>
</template>
<script>
import StripeService from '@/services/stripe-service/stripe.service';
import FormService from '@/services/form-service/form.service';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { toast } from 'vue3-toastify';
import './HomeView.scss';
// Components
import Packages from '@/components/Packages/Packages.vue';
export default {
  inject: ['agentsOptions', 'yesNoOptions'],
  components: {
    Packages
  },
  data() {
    return {
      v$: useVuelidate(),
      stripeLoaded: false,
      isPayLoading: false,
      isPayCompleted: false,
      isPackageSelected: false,
      packageSelected: null,
      packagesList: [
        // {
        //   id: 0,
        //   packageName: "Basic",
        //   amount: 10000,
        //   amountWithTax: 8100,
        // },
        {
          id: 2,
          packageName: 'Standard',
          amount: 10000,
          amountWithTax: 8100
        }
        // {
        //   id: 3,
        //   packageName: "Premier",
        //   amount: 10000,
        //   amountWithTax: 8100,
        // },
      ],
      payCompletedId: null,
      isDisabledFormLink: true,
      isAppCreateLoading: false,
      isAppCreateError: false,
      stripe: null,
      cardElement: null,
      addressElement: null,
      fullName: null,
      firstName: null,
      lastName: null,
      shippingAddress: null,
      phone: null,
      email: '',
      stripeValidationError: '',
      amount: 10000,
      tax: true,
      customer: null,
      isAgreeTermsConditions: false,
      latest_charge: null,
      howDidYouHearAboutUs: '',
      agent: this.$route.name === 'payWithMoto' ? 'Mike' : null,
      leadId: this.$route.query.leadId ?? null,
      isDisabledFullFormLink: true,
      isPayWithMoto: this.$route.name === 'payWithMoto' ? true : false,
      class3Appeal: false,
      hasNINONow: false,
      paymentAfterJan: false
    };
  },
  computed: {
    agentsOptionsForMoto() {
      return this.agentsOptions.filter((item) => item.value);
    }
  },
  async mounted() {
    if (this.$route?.name == 'now') {
      this.packageSelected = 'Standard';
      this.isPackageSelected = true;
      this.showStripeLoading();
      try {
        // eslint-disable-next-line no-undef
        this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
        await this.hideStripeLoading();
        this.createAndMountFormElements();
      } catch (err) {
        await this.showStripeLoading();
        console.log(err);
      }
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('Email address required', required),
        email
      }
    };
  },
  methods: {
    formatAmount(amount) {
      return amount / 100;
    },
    async handleSelectPackage(packageItem, tax) {
      this.isPackageSelected = true;
      this.tax = tax;
      this.amount = this.tax ? packageItem.amount : packageItem.amountWithTax;
      this.packageSelected = packageItem.packageName;
      this.showStripeLoading();
      try {
        // eslint-disable-next-line no-undef
        this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
        await this.hideStripeLoading();
        this.createAndMountFormElements();
      } catch (err) {
        await this.showStripeLoading();
        console.log(err);
      }
    },
    showStripeLoading() {
      this.stripeLoaded = false;
    },
    hideStripeLoading() {
      this.stripeLoaded = true;
    },
    setTouched(theModel) {
      if (theModel === 'email' || theModel == 'all') {
        this.v$.email.$touch();
      }
    },
    async createAndMountFormElements() {
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
        hidePostalCode: true
      });
      this.cardElement.mount('#card-element');
      const options = {
        mode: 'billing',
        fields: {
          phone: 'always',
          name: 'always'
        },
        display: {
          name: 'split'
        },
        validation: {
          phone: {
            required: 'always'
          }
        }
      };
      this.addressElement = elements.create('address', options);
      this.addressElement.mount('#address-element');

      this.cardElement.on('change', this.setValidationCardError);
      this.addressElement.on('change', this.setValidationError);
    },
    setValidationCardError(event) {
      this.stripeValidationError = event.error ? event.error.message : '';
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : '';
      if (event.complete) {
        this.shippingAddress = event.value.address;
        this.fullName = event.value.name;
        this.firstName = event.value.firstName;
        this.lastName = event.value.lastName;
        this.phone = event.value.phone;
      } else {
        this.shippingAddress = null;
        this.fullName = null;
        this.firstName = null;
        this.lastName = null;
        this.phone = null;
      }
    },
    async pay() {
      try {
        this.setTouched('all');
        if (!this.v$.$invalid) {
          if (this.fullName && this.phone && this.shippingAddress && this.email) {
            await this.showPayLoading();
            const result = await this.stripe.createPaymentMethod({
              type: 'card',
              card: this.cardElement,
              billing_details: {
                name: this.fullName,
                email: this.email,
                phone: this.phone,
                address: this.shippingAddress
              }
            });

            this.stripePaymentMethodHandler(result);
          } else {
            this.stripeValidationError = 'Fill in your personal data!';
          }
        }
      } catch (error) {
        this.hidePayLoading();
        console.error(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      }
    },
    async stripePaymentMethodHandler(result) {
      try {
        if (result.error) {
          this.stripeValidationError = result.error.message;
          toast.error(result.error.message ?? 'Oops, Something Went Wrong');
          await this.hidePayLoading();
        } else {
          const res = await StripeService.sendFormData({
            payment_method_id: result.paymentMethod.id,
            amount: this.amount,
            email: this.email,
            setup_future_usage: false,
            shiping_details: {
              name: this.fullName,
              phone: this.phone,
              address: this.shippingAddress
            },
            isMoto: this.$route.name === 'payWithMoto' ? true : false,
            howDidYouHearAboutUs: this.howDidYouHearAboutUs,
            agent: this.agent,
            leadId: this.leadId,
            class3Appeal: this.class3Appeal,
            paymentAfterJan: this.paymentAfterJan,
            hasNINONow: this.hasNINONow
          });
          const paymentResponse = res;
          this.handleServerResponse(paymentResponse);
        }
      } catch (error) {
        this.hidePayLoading();
        console.error(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      }
    },
    async handleServerResponse(response) {
      try {
        if (response.error) {
          this.stripeValidationError = response.error.message;
          toast.error(response.error.message ?? 'Oops, Something Went Wrong');
        } else if (response.requires_action) {
          const { error: errorAction, paymentIntent } = await this.stripe.handleCardAction(
            response.payment_intent_client_secret
          );
          if (errorAction) {
            // this.stripeValidationError = response.error.message;
          } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            const serverResponse = await await StripeService.sendFormData({
              payment_intent_id: paymentIntent.id
            });
            this.handleServerResponse(await serverResponse);
          }
        } else {
          const { id, status, paymentMethod, customer, latest_charge } = response;
          console.log('Payment ID', id);
          console.log('Status', status);
          console.log('Customer', customer);
          console.log('Latest charge', latest_charge);
          this.customer = customer;
          this.latest_charge = latest_charge;
          if (status === 'Success' && id && paymentMethod) {
            this.isPayCompleted = true;
            this.payCompletedId = id;
            this.addDataLayer();
            if (this.$route.name !== 'payWithMoto') {
              await this.showCreateAppLoading();
              try {
                const response = await FormService.createApp({
                  id: id,
                  amount: this.amount,
                  email: this.email,
                  name: this.fullName,
                  firstName: this.firstName,
                  lastName: this.lastName,
                  phone: this.phone,
                  address: this.shippingAddress,
                  packageName: this.packageSelected,
                  paymentMethod: paymentMethod,
                  customer: customer ?? null,
                  tax: this.tax,
                  latest_charge: this.latest_charge,
                  howDidYouHearAboutUs: this.howDidYouHearAboutUs,
                  agent: this.agent
                });
                if (response.status === 'Success') {
                  this.isDisabledFormLink = false;
                  this.isAppCreateError = false;
                } else {
                  toast.error(response.status ?? 'Oops, Something Went Wrong');
                  this.isAppCreateError = true;
                }
                this.hideCreateAppLoading();
              } catch (error) {
                this.isAppCreateError = true;
                this.hideCreateAppLoading();
                toast.error(error?.message ?? 'Oops, Something Went Wrong');
                console.error(error);
              }
            } else {
              setTimeout(() => {
                this.isDisabledFullFormLink = false;
              }, 30000);
            }
          }
        }
        this.hidePayLoading();
      } catch (error) {
        this.isAppCreateError = true;
        this.hidePayLoading();
        toast.error(error?.message ?? 'Oops, Something Went Wrong');
        console.error(error);
      }
    },
    showPayLoading() {
      this.isPayLoading = true;
    },
    hidePayLoading() {
      this.isPayLoading = false;
    },
    showCreateAppLoading() {
      this.isAppCreateLoading = true;
    },
    hideCreateAppLoading() {
      this.isAppCreateLoading = false;
    },
    navigateToForm(id) {
      this.$router.push({ name: 'form', params: { id: id } });
    },
    navigateToFullForm(id) {
      this.$router.push({ name: 'full-form', params: { id: id } });
    },
    addDataLayer() {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: this.payCompletedId,
          value: this.amount / 100,
          tax: this.tax ? 0 : 100 - this.amount / 100,
          shipping: 0.0,
          currency: 'EUR',
          coupon: '',
          items: [
            {
              item_id: '',
              item_name: this.packageSelected,
              affiliation: '',
              coupon: '',
              discount: '',
              item_brand: 'xtrapension.com',
              item_category: 'UK State Pension',
              item_list_name: '',
              item_variant: 'shipping_variant',
              price: this.amount / 100,
              quantity: 1
            }
          ]
        },
        country: this.shippingAddress.country,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        userId: this.customer
      });
    }
  }
};
</script>
